// extracted by mini-css-extract-plugin
export var ArtistDescription = "HerveDiRosa-module--ArtistDescription--16J9F";
export var ArtistInfos = "HerveDiRosa-module--ArtistInfos--WcdlH";
export var ButtonWrapper = "HerveDiRosa-module--ButtonWrapper --mwGqs";
export var CardWrapper = "HerveDiRosa-module--CardWrapper--zkzb+";
export var CarrouselWrapper2 = "HerveDiRosa-module--CarrouselWrapper2--qu4j7";
export var Citations = "HerveDiRosa-module--Citations--uNzru";
export var DescriptionWrapper = "HerveDiRosa-module--DescriptionWrapper--l0ndj";
export var ImageWrapper = "HerveDiRosa-module--ImageWrapper--mGBk+";
export var LinkWrapper = "HerveDiRosa-module--LinkWrapper--94Q9N";
export var MobileProtrait = "HerveDiRosa-module--MobileProtrait---tIZp";
export var More = "HerveDiRosa-module--More--GnfCe";
export var MoreButton = "HerveDiRosa-module--MoreButton--+B+DG";
export var NameWrapper = "HerveDiRosa-module--NameWrapper--7651K";
export var PdpWrapper = "HerveDiRosa-module--PdpWrapper--9wnCh";
export var PhotosWrapper = "HerveDiRosa-module--PhotosWrapper--6W+s+";
export var ProfilWrapper = "HerveDiRosa-module--ProfilWrapper--1Z3WT";
export var TitleWrapper = "HerveDiRosa-module--TitleWrapper--bXVPL";
export var Wrapper = "HerveDiRosa-module--Wrapper--re3nW";